input {
    padding: 10px;
    margin: 5px;
    background-color: var(--backgroundMainColor);
    color: var(--textMainColor);
    border-radius: 10px;
    border-width: 0px;
    font-family: var(--fontMain);
    width: 70px;
    -webkit-box-shadow: var(--shadowColor) 0px 1px 1px 0px;
    box-shadow: var(--shadowColor) 0px 1px 1px 0px;
}

.react-datepicker-wrapper {
    width: 100px;
    overflow: visible;
}
.react-datepicker {
    background-color: var(--backgroundMainColor);
    border-color: transparent;
}

.react-datepicker__header {
    background-color: var(--backgroundSecondaryColor);
    color: var(--textMainColor);
    border-color: transparent;
    font-family: var(--fontMain);
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    color: var(--textMainColor);
}

.react-datepicker__month-container {
    background-color: var(--backgroundMainColor);
    color: var(--textMainColor);
}
.react-datepicker__day-names, .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
    color: var(--textMainColor);
}
.react-datepicker__day:hover, .react-datepicker__day--selected {
    background-color: var(--accentColor);
    color: var(--textAccentColor);
}
.react-datepicker__input-container {
    border-radius: 10px;
    border-width: 0px;
    overflow: visible;
}
.react-datepicker-ignore-onclickoutside {
}
.react-datepicker--time-only {
    min-width: 100px;
}
.react-datepicker__time-container {
    width: 100%;
    min-width: 50px;
}
.react-datepicker__time {
    width: 100%;
    background-color: var(--backgroundMainColor) !important;
}
.react-datepicker__time-box {
    width: 100% !important;
    margin: 0px !important;
}
.react-datepicker__time-list-item {
    color: var(--textMainColor);
    font-family: var(--fontMain);
}
.react-datepicker__time-list-item--selected {
    background-color: var(--accentColor) !important;
    color: var(--textAccentColor) !important;
}
.react-datepicker__time-list-item--disabled {
    background-color: var(--borderColor);
}
.time {
    width: 60px;
}
.timeinput {
    width: 40px;
}